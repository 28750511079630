export const equipment = [
  {
    type: 'Sypialnia 1',
    equipment: [
      {
        type: 'Łóżko 140/200',
      },
      {
        type: 'Łóżko 140/200',
      },
      {
        type: 'Komoda',
      },
      {
        type: 'Pościel',
      },
      {
        type: 'Stolik nocny',
      },
    ],
  },
  {
    type: 'Sypialnia 2',
    equipment: [
      {
        type: 'Łóżko 140/200',
      },
      {
        type: 'Łóżko 120/200',
      },
      {
        type: 'Komoda',
      },
      {
        type: 'Pościel',
      },
      {
        type: 'Stolik nocny',
      },
    ],
  },
  {
    type: 'Łazienka',
    equipment: [
      {
        type: 'WC',
      },
      {
        type: 'Prysznic',
      },
      {
        type: 'Regał na kosmetyki',
      },
      {
        type: 'Umywalka',
      },
    ],
  },
  {
    type: 'Pokój z aneksem kuchennym',
    equipment: [
      {
        type: 'Rozkładany narożnik',
      },
      {
        type: 'Komoda',
      },
      {
        type: 'Pościel',
      },
      {
        type: 'Regał na kosmetyki',
      },
      {
        type: 'Stolik kawowy',
      },
      {
        type: 'Telewizor 32"',
      },
      {
        type: 'Kuchenka indukcyjna',
      },
      {
        type: 'Czajnik elektryczny',
      },
      {
        type: 'Lodówko-zamrażarka',
      },
      {
        type: 'Naczynia do gotowania (garnki,patelnia)',
      },
      {
        type: 'Serwis obiadowy',
      },
      {
        type: 'Krzesełko dla dzieci',
      },
    ],
  },
  {
    type: 'Taras',
    equipment: [
      {
        type: 'Stół z ławkami',
      },
      {
        type: 'Leżaki',
      },
      {
        type: 'Parawan',
      },
      {
        type: 'Grill',
      },
    ],
  },
];

export const trx = {
  roomEquipment: {
    room1: {
      type: 'Bedroom1',
      equipment: [
        {
          type: 'Bed 140/200',
        },
        {
          type: 'Bed 140/200',
        },
        {
          type: 'dresser',
        },
        {
          type: 'linen',
        },
        {
          type: 'bedside table',
        },
      ],
    },
    room2: {
      type: 'Bedroom 2',
      equipment: [
        {
          type: 'Bed 140/200',
        },
        {
          type: 'Bed 140/200',
        },
        {
          type: 'dresser',
        },
        {
          type: 'linen',
        },
        {
          type: 'bedside table',
        },
      ],
    },
    bathroom: {
      type: 'bathroom',
      equipment: [
        {
          type: 'WC',
        },
        {
          type: 'shower',
        },
        {
          type: 'Shelf for cosmetics',
        },
        {
          type: 'Sink',
        },
      ],
    },
    livingRoom: {
      type: 'Room with kitchenette',
      equipment: [
        {
          type: 'Fold-out corner',
        },
        {
          type: 'dresser',
        },
        {
          type: 'linen',
        },
        {
          type: 'Shelf for cosmetics',
        },
        {
          type: 'A coffee table',
        },
        {
          type: 'TV 32',
        },
        {
          type: 'Induction cooker',
        },
        {
          type: 'Electric Kettle',
        },
        {
          type: 'Fridge Freezer',
        },
        {
          type: 'Cooking utensils (pots, frying pan) ',
        },
        {
          type: 'Dinner service',
        },
        {
          type: 'High chair for children ',
        },
      ],
    },
    terrace: {
      type: 'Tarrace',
      equipment: [
        {
          type: 'Table with benches',
        },
        {
          type: 'sun loungers',
        },
        {
          type: 'windbreak',
        },
        {
          type: 'Grill',
        },
      ],
    },
  },
};
